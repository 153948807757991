@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

nav{
  /* border-bottom: 2px solid #00651d; */
  background: #111e3e;
  color: #9dfcb5;
  padding: 0;
}

nav .logo{
  height: 50px;
  margin: 4px;
}

nav .nav-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media  screen and (max-width: 700px) {
  nav .nav-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  nav{
    padding: 5px ;
  }
}

nav .nav-container ul{
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav .nav-container ul li {
  font-size: 18px;
  margin: 0 10px;
}

nav .nav-container ul li a{
  font-family: 'Courier New', Courier, monospace;
  text-decoration: none;
  background: #00faaf;
  color: #111e3e;
  padding: 3.5px 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  border-radius: 4px;
}

nav .nav-container ul li a:hover{
  background: #aaa;
  /* font-size: 25px; */
}

nav .nav-container ul li a.active {
  background: #efefef;
}

.home-wrapper{
  font-family:'Consolas','Courier New', Courier, monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91vh;
  background: #001c23;
  color: #00faaf;
}

.home-wrapper .form-control,
.home-wrapper .form-control:focus,
.home-wrapper .form-control:-webkit-autofill,
.home-wrapper .form-control:-webkit-autofill:hover, 
.home-wrapper .form-control:-webkit-autofill:focus,
.home-wrapper .form-control:-webkit-autofill:active,
.home-wrapper .form-control{
  background: #001c23 !important;
  -webkit-box-shadow: 0 0 0px 1000px #001c23 inset !important;
  border: 2px solid  #00651d;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}



.code-wrapper{
  overflow: hidden;
  height: 100%;
  position: relative;
  font-family: 'Fira code', monospace;
}

.aside {
  background-color: #001c23;
  position: relative;
  color: #c7ccd0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
}



.aside.collapsed *{
  display: none;
}


.aside span{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%,-50%);
  z-index: 90000;
}

.aside.collapsed span{
  display: block;
  transform: translate(100%,-50%);
}

.aside span::before{
  content: '\27E8';
  font-size: 20px;
  font-weight: 900;
    text-shadow: 2px 0px #ddd;
  color: #ddd;
  background: #121;
  padding: 10px 8px 15px 6px;
  border-radius:  10px  0 0 10px;
  border: 1px solid rgb(109, 109, 109);
  border-right: 0px;
}

.aside.collapsed span::before{
  content: '\27E9';
  border-radius: 0 10px 10px 0;
  border-right: 1px;
  border-left: 0px;
}



.members-wrapper{
  padding: 5px;
}

.members-wrapper h4 {
  font-weight: 400;
margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.members-wrapper .single-member{
  padding: 3px 4px;
  font-family:'Consolas','Courier New', Courier, monospace;
  box-shadow: 0 2px 3px #07bbdf,
              0 -2px 3px #000000;
  border: 1px solid #033;
  border-radius: 5px;
  color: #efefef;
  background: #033;
  font-size: 18px;
  font-weight: 500;
  margin: 6px 0;
}



.toast-container {
  top: 20px; /* Adjust the top distance as needed */
  right: 20px; /* Adjust the right distance as needed */
  z-index: 9999; /* Ensure a higher z-index value to appear above other elements */
}

.theme-dropdown{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  background: #000000;
  border-radius: 3px;
  padding: 4px 8px;
  color: #fff;
}

.theme-dropdown label{
  margin-right: 7px;
  color: #00faaf;
}

.theme-dropdown select{
  background: #000000;
  border: none;
  outline: none;
  color: #fff;
}


.btns-container{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 6px;
}

#root button{
  border: none;
  outline: none;
  padding: 8px 14px;
  margin-bottom: 7px;
  border-radius: 3px;
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-size: 16px;
  text-transform: uppercase;
  transition: 100ms linear;
  color: #fff;
  box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.7),
             inset  2px 2px 0px rgba(255, 255, 255, 0.7);
}

#root button:disabled{
  opacity: 50%;
}

#root button:enabled:hover{
  /* background: #333; */
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.9),
             inset  -1px -1px 0 rgba(255, 255, 255, 0.6);
}

button.success {
  background: #00830b;
}


button.danger {
  background: #710;
}

button.primary{
  background: #390067;
}





#output {
  height: 85vh;
  background: #111;
  color: #fff;
  overflow-y: scroll;
  margin: 0;
}

#output::-webkit-scrollbar {
  width: 0.5em;
}

#output::-webkit-scrollbar-track {
  background-color: transparent;
}

#output::-webkit-scrollbar-thumb {
  background-color: #888;
}

#output::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.about-wrapper{
  color: #fff;
  padding: 10px;
  font-family: 'Fira code', monospace;
}

.about-wrapper h2{
  font-size: 30px;
  font-weight: 600;
  color: #32ffb0;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 1px 1px #000,
  2px 2px 1px #ffffff80;
  text-align: center;
  text-rendering:optimizeLegibility;
  margin: 30px 0;
  position: relative;
}

.about-wrapper h2::after{
  position: absolute;
  content: '';
  width: 35%;
  height: 3px;
  background: #fff;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: linear-gradient(to right, #32ffb0, #32ffb099, #32ffb0);
  border-radius: 6px;
}

.about-wrapper p{
  font-size: 18px;
}

.my-name{
  display: inline-block;
  background: linear-gradient(30deg, #007755, #005588);
  padding: 1px 10px;
  border-radius: 10px;
  border: 2px solid #000;
  letter-spacing: 1.3px;
  font-weight: 600;
  text-shadow: 1px 1px #000, -1px -1px #000;
  cursor: pointer;
}

.my-name:hover{
  transform: scale(5);
}

